import React, { useEffect, useState } from "react";
import type { NextPage } from "next";
import Head from "next/head";
import styles from "../styles/Home.module.css";
import { useRouter } from "next/router";
import { useDentralAuth } from "../hooks/useDentralAuth";
import Toast from "../components/Toast";
import { useTranslation } from "react-i18next";

const Home: NextPage = () => {
  const { buildAuthorizeUrl, isLoading, user } = useDentralAuth();
  const [toastMessage, setToastMessage] = useState("");
  const router = useRouter();
  const { t } = useTranslation("global");

  const login = () => {
    return buildAuthorizeUrl({
      redirect_uri: window.location.origin,
      redirectMethod: "replace",
    })
      .then((url) => {
        router.push(url);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (user) {
      router.push("/search");
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("registration_successful") === "true") {
      setToastMessage(t("registration_successful"));
    }
  }, [user, router, t]);

  return (
    <div className={styles.container}>
      {toastMessage && (
        <div className="inline-flex w-full left-0 absolute">
          <Toast message={toastMessage}></Toast>
        </div>
      )}
      <Head>
        <title>{t("head_title")}</title>
        <meta name="description" content={t("head_description")} />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>{t("welcome_dentral")}</h1>
        <h2 className={styles.subtitle}>{t("welcome_dentral_subtitle")}</h2>
        {isLoading ? (
          <div>{t("loading")}...</div>
        ) : (
          <React.Fragment>
            <h3 className="text-center w-6/12 mt-10">{t("welcome_savings")}</h3>
            <h3 className="text-center w-6/12 m-5">
              {t("welcome_explanation")}
            </h3>
            <h3 className="text-center w-6/12 m-5">{t("welcome_greetings")}</h3>
            <div>
              <button
                onClick={() => router.push("/signup")}
                className="col-span-6 sm:col-span-4 sm:col-start-2 xl:col-span-2 xl:col-start-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-25"
              >
                {t("cta_signup")}
              </button>
              <button
                onClick={() => login()}
                className="col-span-6 ml-4 sm:col-span-4 sm:col-start-2 xl:col-span-2 xl:col-start-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-25"
              >
                {t("sign_in")}
              </button>
            </div>
          </React.Fragment>
        )}
      </main>
    </div>
  );
};

export default Home;
