import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Catalogs } from "../types/search";

const USER_ROLES_KEY = "https://dentral.pt/roles";
const USER_DISCOUNTS_KEY = "https://dentral.pt/discount";
const USER_METADATA_KEY = "https://dentral.pt/metadata";

export const useDentralAuth = () => {
  const auth0 = useAuth0();
  const [discounts, setUserDiscounts] = useState<
    Partial<Record<Catalogs, number>>
  >({ dentaleader: 0, dentalexpress: 0, henryschein: 0, montellano: 0, douromed: 0 });
  const [metadata, setUserMetadata] = useState<Record<string, any>>();

  useEffect(() => {
    auth0.getIdTokenClaims().then((token) => {
      const discounts = token?.[USER_DISCOUNTS_KEY];
      if (discounts) {
        setUserDiscounts(discounts);
      }

      const metadata = token?.[USER_METADATA_KEY];
      if (metadata) {
        setUserMetadata(metadata);
      }
    });
  }, [auth0.isAuthenticated, auth0.getIdTokenClaims, auth0]);

  return {
    ...auth0,
    discounts,
    metadata,
  };
};

export type DentralAuth = ReturnType<typeof useDentralAuth>;
